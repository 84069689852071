import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { ChakraProvider, Box } from '@chakra-ui/react'
import theme from '../theme/theme'
import { extendTheme } from '@chakra-ui/react'
import SVGTriangle from '../images/triangle.inline.svg'
import Header from './Header'
import gsap from "gsap"
import SVGAKMap from '../images/akmap.svg'
import SVGBuilding from '../images/building.svg'
import './layout.scss'


const LayoutLazer = ({ children }) => {
  const [render, setRender] = useState(false)
  const themeObj = extendTheme({ ...theme})
  const data = useStaticQuery(graphql`
    query SiteTitleLazerQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // LAZER Animate triangles
  const gsapTriangleAnimate = () => {
    gsap.set('.triangle path', {transformOrigin: 'center'})
    gsap.set('#Triangle1', {rotation: '-=10'})
    gsap.set('#Triangle2', {rotation: '-=15'})
    gsap.set('#Triangle3', {rotation: '-=20'})
    gsap.set('#Triangle4', {rotation: '-=25'})
    // Don't execute animation if window width 800px or less
    if (typeof window !== 'undefined') {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      if (vw > 80000) { // temp disabled
        gsap.to('.triangle path', {rotation: '+=360', duration: 12, ease: 'power1.inOut', repeat: -1, stagger: 0.5, yoyo: true})
      } 
    }
  }
  useEffect(() => {
    // Run LAZER triangles
    gsapTriangleAnimate()
    setRender(true)
  }, [])

  return (
    <ChakraProvider theme={themeObj}>

      <Box>

        <Header siteTitle={data.site.siteMetadata?.title || `Alopex Lazer`} />

        {render &&
          <Box
            key={`fffffff`}
            className={`lazersWrapper`}
            backgroundImage={`url(${SVGAKMap})`}
            backgroundPosition={['right 170px', null, 'right top']}
            backgroundRepeat="no-repeat"
            backgroundSize={['100%', null, '60%']}
            _before={{
              content: '""',
              position: 'absolute',
              bottom: '1024px',
              right: 0,
              backgroundImage: `url(${SVGBuilding})`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: '100px',
              width: '50vw',
              height: '100vh',
            }}
          >
            <div className="lazerLine" key={`lazer1`}></div>
            <div className="lazerLine" key={`lazer2`}></div>
            <div className="lazerLine" key={`lazer3`}></div>
          </Box>
        }

        <Box position="relative" overflow="hidden">
          <SVGTriangle className="triangle" />
          <Box as="main" position="relative" zIndex="1">
            {children}
          </Box>
        </Box>

      </Box>

    </ChakraProvider>
  )
}

LayoutLazer.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string
}

export default LayoutLazer
