import React from 'react'

export const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false
}

export const breakpoints = {
    sm: '420px',
    md: '640px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1440px',
}

export const colors = {
    brand: {
        'darkblue': '#000538', // dark blue bg
        'darkblue': '#000538', // dark blue bg
        // 'blue': '#0017FF', // light blue foreground
        'blue': '#004bff', // light blue foreground
        'lightblue': '#5CD5F4',
        'hotpink': '#D41679',
        'lightgray': '#f1f1f1',
        'darkgray': '#37474f',
        'white': '#ffffff',
    }
}

export const typography = {
    fonts: {
        body: 'Abel',
        heading: 'Abel',
        mono: 'monospace',
    },
    fontSizes: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        '2xl': '24px',
        '3xl': '28px',
        '4xl': '36px',
        '5xl': '48px',
        '6xl': '64px',
    },
    fontWeights: {
        normal: 400,
        medium: 500,
        bold: 700,
    },
    lineHeights: {
        normal: 'normal',
        none: '1',
        shorter: '1.25',
        short: '1.375',
        base: '1.5',
        tall: '1.625',
        taller: '2',
    }
}

export const textStyles = {
    h1: {
        fontSize: ['5xl', '6xl'],
        fontWeight: 'normal',
    },
    h2: {
        fontSize: ['4xl', '5xl'],
        fontWeight: 'normal',
    },
    h3: {
        fontSize: ['3xl', '4xl'],
        fontWeight: 'normal',
        lineHeight: 'base'
    },
    h4: {
        fontSize: ['2xl', '3xl'],
        fontWeight: 'normal',
    },
    h5: {
        fontSize: ['1xl', '2xl'],
        fontWeight: 'normal',
    },
    h6: {
        fontSize: ['sm', 'sm'],
        fontWeight: 'normal',
    },
    a: {
        color: 'brand.lightblue',
        transition: 'color 0.2s ease-in-out',
        textDecoration: 'underline',
        '&:hover': {
            color: 'white'
        }
    },
    description: {
        lineHeight: 1.5,
        color: colors.brand.blue,
        marginBottom: '40px',
        fontSize: '36px',
        span: {
            color: colors.brand.lightblue
        },
        '*': {
            lineHeight: 'inherit',
            color: 'inherit',
            // marginBottom: 'inherit',
            fontSize: 'inherit'
        }
    },
    gradText0: {
        backgroundImage: 'linear-gradient(to right, #5CD5F4, #D41679)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },
    gradText1: {
        backgroundImage: 'linear-gradient(to right, #5CD5F4, #0017FF, #D41679)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        display: 'table',
        '*:not(a)': {
            backgroundImage: 'linear-gradient(to right, #5CD5F4, #0017FF, #D41679)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            display: 'table',
        },
        a: {
            // borderBottom: `2px solid ${colors.brand.lightblue}`,
            '-webkit-text-fill-color': colors.brand.lightblue,
            display: 'inline-block',
            lineHeight: 1,
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
            '-webkit-text-fill-color': '#ffffff',
            borderColor: '#ffffff'
            }
        }
    },
    gradText2: {
        backgroundImage: `linear-gradient(to right, ${colors.brand.lightblue}, ${colors.brand.blue})`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        '*:not(a)': {
            backgroundImage: `linear-gradient(to right, ${colors.brand.lightblue}, ${colors.brand.blue})`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
        },
        a: {
            backgroundImage: 'none'
        }
    }
}

export const styles = {
    global: props => ({
    body: {
        // bg: mode('dark', '#000538')(props),
        bg: props.colorMode === 'dark' ? colors.brand.darkblue : colors.brand.lightblue,
        color: props.colorMode === 'dark' ? 'brand.lightblue' : 'brand.blue',
        position: 'relative',
        fontSize: 'lg',
    },
    a: {
        color: 'brand.lightblue',
        textDecoration: 'underline',
        transition: 'color 0.3s ease-in-out',
        '&:hover': {
            color: 'white'
        }
    },
    p: {
        fontSize: 'lg',
        marginBottom: '40px'
    },
    h1: {
        fontSize: '6xl'
    },
    h2: {
        fontSize: '5xl'
    },
    h3: {
        fontSize: '4xl',
    },
    h4: {
        fontSize: '3xl'
    },
    h5: {
        fontSize: '2xl'
    },
    h6: {
        fontSize: 'sm',
        color: props.colorMode === "dark" ? 'brand.lightblue' : 'brand.darkblue'
    },
    // FORMS
    'input': {
        minHeight: '55px',
    },
    'input[type="text"]': {
        borderRadius: 0,
        border: `1px solid rgba(92, 213, 244, 0.6)`,
        '&:hover': {
        borderColor: 'rgba(92, 213, 244, 0.85)',
        },
        '&::placeholder': {
        color: `${props.theme.colors.brand.lightblue}`
        }
    },
    'input[type="submit"]': {
        borderRadius: 0,
        border: 'none',
        cursor: 'pointer',
        backgroundColor: 'brand.lightblue',
        color: 'brand.darkblue',
        transition: 'background-color 0.5s ease-in-outerHeight, color 0.5s ease-in-out',
        '&:hover': {
        backgroundColor: 'transparent',
        color: props.theme.colors.brand.lightblue,
        border: `1px solid ${props.theme.colors.brand.lightblue}`
        }
    }
    }),
};

const themeLazer = {
    config,
    breakpoints,
    colors,
    ...typography,
    textStyles,
    styles
}

export default themeLazer