import PropTypes from "prop-types"
import { Box } from "@chakra-ui/react"
import React from "react"
import SVGTriangle from "../images/alopex_triangle.svg"
import SVGAlopexLogo from "../images/alopex_logo.inline.svg"

const HeaderLazer = ({ siteTitle }) => (
  <Box as="header" background="#f1f1f1" color="#37474f" mb="100px" height="45px" position="relative" zIndex="500">
    <Box m="0 auto" maxWidth="960px" position="relative" padding="0 15px">
      <Box
        as="a"
        display="block"
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        top="0"
        width="120px"
        height="130px"
        backgroundImage={`url(${SVGTriangle})`}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="bottom center"
        href="https://alopexid.com/"
      >
        <SVGAlopexLogo
          className="alopexLogo"
          alt={siteTitle}
          style={{
            maxWidth: '54px',
            width: '100%',
            height: 'auto',
            margin: '25px auto 0 auto',
            fill: 'url(#logoGradient)'
          }}
        />
      </Box>
    </Box>
  </Box>
)

HeaderLazer.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderLazer.defaultProps = {
  siteTitle: ``,
}

export default HeaderLazer
